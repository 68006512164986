<template>
  <div class="p-1">
    <div class="flex flex-wrap justify-between mb-2">
      <a-button @click="$router.back()">
        Regresar
      </a-button>
    </div>
    <div class="flex justify-between items-center">
        <h3 class="text-2xl my-4" v-if="teacher">
            {{ `${teacher.first_name} ${teacher.last_name}` }}
        </h3>
        <p v-if="teacher && !userEnabled" class="text-red-400">Desactivado</p>
    </div>

    <div class="space-y-4">
      <a-card v-if="teacher && !loadings.teacher">
        <template #header>
          <div class="flex justify-between items-start">
            <div class="flex">
              <div class="mr-2 mb-2">
                <img alt="profile picture" v-if="profilePicture" :src="profilePicture" class="rounded-full w-32 h-32 shadow-md" />
                <template v-else>
                  <span class="bg-gradient-to-br from-blue-300 to-blue-500 h-32 w-32 rounded-full flex justify-center items-center shadow-md">
                   <span class="text-3xl text-white">{{ teacher.first_name.charAt(0) }}</span>
                  </span>
                </template>
              </div>
              <div>
                <h4>{{ teacher.is_coordinator ? lang.app.roles.coordinator : lang.app.roles.teacher }}</h4>
                <p>{{ teacher.id_document }}</p>
              </div>
            </div>
            <a-dropdown>
              <template #menu>
                <a-dropdown-item :to="isCoordinator ? { path: `/coordinators/${teacher.id}/schedule` } : { name: 'teachers.schedule', params: { id: teacher.id } }">
                  Ver horario
                </a-dropdown-item>
                <a-dropdown-item
                    :to="isCoordinator ? { path: `/coordinators/${teacher.id}/historic` } : { name: 'teacher_historic', params: { id: teacher.id } }"
                    v-if="teacher && (user.isAdminGroup || teacher.supervisor_teacher_id === user.teacher.id)">
                  Ver historial
                </a-dropdown-item>
                <a-dropdown-item :to="isCoordinator ? { path: `/coordinators/${teacher.id}/edit` } : { name: 'teachers.edit', params: { id: teacher.id } }" v-if="user.isAdmin">
                  Editar
                </a-dropdown-item>
                <a-dropdown-item @click="toggleEnabled(teacher)" v-if="user.isAdmin">
                  {{ teacher.enabled ? 'Deshabilitar' : 'Habilitar' }}
                </a-dropdown-item>
                <a-dropdown-item
                  @click="sendEmailModal = true"
                  v-if="user.isAdmin">
                  Enviar correo de confirmación
                </a-dropdown-item>
                  <a-dropdown-item
                      v-if="user.isAdmin"
                      @click="disabledUserModal = true">
                      {{ userEnabled ? 'Deshabilitar usuario' : 'Habilitar usuario' }}
                  </a-dropdown-item>
                <a-dropdown-item @click="destroy(teacher)" v-if="userEnabled && user.isAdmin" :disabled="loadings.deleting">
                  Eliminar <a-loader v-if="loadings.deleting" />
                </a-dropdown-item>
              </template>
            </a-dropdown>
          </div>
        </template>
        <div class="flex flex-wrap mb-2">
          <div class="w-full md:w-1/5 lg:w-1/5">
            <b style="color: #4D4F5C">Correo electrónico</b>
          </div>
          <div class="w-full md:w-1/1 lg:w-1/2">
            {{ teacher.email }}
          </div>
        </div>

        <div class="flex flex-wrap mb-2">
          <div class="w-full md:w-1/5 lg:w-1/5">
            <b style="color: #4D4F5C">Teléfono de habitación</b>
          </div>
          <div class="w-full md:w-1/1 lg:w-1/2">
            {{ teacher.phone }}
          </div>
        </div>

        <div class="flex flex-wrap mb-2">
          <div class="w-full md:w-1/5 lg:w-1/5">
            <b style="color: #4D4F5C">Teléfono celular</b>
          </div>
          <div class="w-full md:w-1/1 lg:w-1/2">
            {{ teacher.mobile_phone }}
          </div>
        </div>

        <div class="flex flex-wrap mb-2">
          <div class="w-full md:w-1/5 lg:w-1/5">
            <b style="color: #4D4F5C">Dirección</b>
          </div>
          <div class="w-full md:w-1/1 lg:w-1/2">
            {{ teacher.address }}
          </div>
        </div>
      </a-card>

      <!-- SKELETONABLE SCREEN -->
      <template v-else>
        <a-skeleton class="h-8 w-44 rounded-full mb-2" />
        <a-card>
          <div class="flex flex-col divide-y space-y-2 mb-4">
            <div class="flex space-x-2 justify-between pt-2">
              <div class="flex-grow flex">
                <a-skeleton circle class="w-32 h-32 rounded-full" />
                <div class="flex flex-col space-y-2">
                  <a-skeleton class="h-4 w-32" />
                  <a-skeleton class="h-4 w-36" />
                </div>
              </div>
              <a-skeleton class="w-8 h-8 rounded-full" />
            </div>
            <div class="flex flex-col space-y-2 pt-2">
              <a-skeleton class="h-4 w-36" />
              <a-skeleton class="h-4 w-36" />
              <a-skeleton class="h-4 w-36" />
            </div>
          </div>
        </a-card>
      </template>
      <div class="flex gap-2">
        <div class="flex flex-col w-56 flex-shrink-0 space-y-1">
          <router-link
            :to="isCoordinator ? { path: `/coordinators/${this.$route.params.id}` } : { name: 'teachers.show', params: { id: this.$route.params.id } }"
            exact-active-class="bg-primary text-white hover:bg-opacity-90"
            class="px-4 py-1.5 rounded-lg hover:bg-opacity-10 hover:bg-primary">
            Materias
          </router-link>
          <router-link
            :to="isCoordinator ? { path: `/coordinators/${this.$route.params.id}/lessons` } : { name: 'teachers.show.lessons', params: { id: this.$route.params.id } }"
            active-class="bg-primary text-white hover:bg-opacity-90"
            class="px-4 py-1.5 rounded-lg hover:bg-opacity-10 hover:bg-primary">
            Clases
          </router-link>
          <router-link
            v-if="teacher && teacher.is_coordinator"
            :to="isCoordinator ? { path: `/coordinators/${this.$route.params.id}/supervising` } : { name: 'teachers.show.supervising', params: { id: this.$route.params.id } }"
            active-class="bg-primary text-white hover:bg-opacity-90"
            class="px-4 py-1.5 rounded-lg hover:bg-opacity-10 hover:bg-primary">
            Profesores
          </router-link>
        </div>

        <router-view />
      </div>
    </div>

    <!-- <a-modal
      @cancel="assignTeachers = !assignTeachers"
      @confirm="assign"
      :confirm-text="loadings.assigning ? 'Asignando...' : 'Asignar'"
      :disabled-confirm="assigning.length === 0"
      :show="teacher && assignTeachers && isCoordinator">
      <div class="flex flex-col space-y-4 items-center" v-if="teacher">
        <h1 class="text-2xl mb-2">Asignar profesores</h1>
        <a-select
          keep-open-on-select
          class="w-full"
          :loading="loadings.teacher || loadings.teachers"
          :source="availableTeachersToAssign"
          multiple
          :selectable="$0 => !teachers.includes($0.value)"
          v-model="assigning" />
      </div>
    </a-modal> -->

    <a-modal
      title="Un momento!!"
      hide-top-cancel
      cancel-text="Ir al listado de coordinadores"
      @cancel="$router.push({ path: '/coordinators' })"
      :show="isCoordinator && teacher && !teacher.is_coordinator">
      <div class="flex flex-col space-y-4 items-center" v-if="teacher">
        <div class="rounded-full bg-gradient-to-br h-16 w-16 from-blue-400 to-blue-700 items-center justify-center flex  text-3xl text-white shadow-md" v-if="!profilePicture">
          {{ `${teacher.first_name} ${teacher.last_name}` | initials }}
        </div>
        <img v-else :src="profilePicture" class="rounded-full w-32 h-32 shadow-md">
        <h1 class="text-xl">
          {{ teacher.first_name }} {{ teacher.last_name }} <b>NO</b> es un coordinador
        </h1>
      </div>
    </a-modal>

    <a-modal
      :show="disabledUserModal"
      @cancel="disabledUserModal = false"
      @confirm="disableUser(teacher)"
      :confirm-text="'Aceptar'"
    >
<!--      <h3 class="text-center text-lg">¿Desea {{ teacher.user.enabled ? 'Deshabilitar' : 'Habilitar' }} este usuario?</h3>-->
      <h3 class="text-center text-lg">{{`¿Desea ${userEnabled ? 'deshabilitar' : 'habilitar'} este usuario?`}}</h3>
    </a-modal>

    <a-modal
      title="Un momento!!"
      hide-top-cancel
      cancel-text="Ir al listado de profesores"
      @cancel="$router.push({ name: 'teachers.index' })"
      :show="error === 403">
      <div class="flex flex-col space-y-4 items-center">
        <h1 class="text-xl">
          No tienes autorización para acceder a la información de este profesor
        </h1>
      </div>
    </a-modal>
    <a-modal
      :show="sendEmailModal"
      @cancel="sendEmailModal = false"
      @confirm="sendEmail(teacher)"
      :confirm-text="'Aceptar'"
    >
      <h3 class="text-center text-lg">¿Desea reenviar el correo de confirmación?</h3>
    </a-modal>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
    props: {
        isCoordinator: { required: false, type: Boolean, default: false }
    },
    data: () => ({
        teacher: null,
        loadings: {
            enabling: false,
            deleting: false,
            assigning: false,
            teacher: false,
            teachers: false
        },
        section: 'courses',
        assignTeachers: false,
        teachers: [],
        teacherEnabled: false,
        userEnabled: false,
        assigning: [],
        error: null,
        periods: [],
        period: null,
        sendEmailModal: false,
        disabledUserModal: false
    }),
    computed: {
        ...mapState({
            user: ({ session }) => session.user,
        }),
        ...mapGetters({
            lang: 'lang'
        }),
        profilePicture() {
            return this.teacher?.profile_picture
        }
    },
    methods: {
        ...mapActions({
            fetchTeacher: 'teachers/show',
        }),
        sendEmail (teacher) {
          this.$repository.users
            .sendConfirmationEmail(teacher.user_id)
            .finally(() => (this.sendEmailModal = false))
        },
        disableUser (teacher) {
            this.$repository.users
                .update(teacher.user_id, { enabled: !this.userEnabled })
                .then(() => {
                    this.fetch()
                })
                .finally(() => (this.disabledUserModal = false))
        },
        fetch() {
            const { id } = this.$route.params

            return this.fetchTeacher({
                id,
                filters: {
                    with: `courseSections.semester,courseSections.section,courseSections.specialization,courseSections.course,courses,user`
                }
              // supervising,
              // supervisor,
            })
            ._loading(() => this.loadings.teacher = !this.loadings.teacher)
            .then(teacher => {
                this.teacher = teacher
                this.userEnabled = teacher.user.enabled
                return teacher
            })
            .catch(err => this.error = err.response?.status)
        },
        toggleEnabled(teacher) {
            this.$confirm({
                message: `¿Seguro que desea ${teacher.enabled ? 'deshabilitar' : 'habilitar'} este ${teacher.is_coordinator ? 'coordinador' : 'profesor'}?`,
                button: {
                    no: 'No',
                    yes: 'Si'
                },
                callback: confirm => {
                    if (confirm) {
                        this.loadings.enabling = !this.loadings.enabling
                        this.$repository.teachers
                            .toggleEnabled(teacher.id, { enabled: !teacher.enabled })
                            ._loading(() => this.loadings.enabling = !this.loadings.enabling)
                            .then(({ data }) => {
                                this.teacherEnabled = data.enabled
                                this.$message.success({
                                    message: `${data.first_name} ${data.last_name} se ha ${data.enabled ? 'habilitado' : 'deshabilitado'}`,

                                })

                                this.teacher = {
                                    ...this.teacher,
                                    ...data
                                }
                            })
                    }
                }
            })
        },
        destroy(teacher) {
            this.$confirm({
                message: `¿Seguro que desea eliminar este ${teacher.is_coordinator ? 'coordinador' : 'profesor'}?`,
                button: {
                    no: 'No',
                    yes: 'Si'
                },
                callback: confirm => {
                    if (confirm) {
                        this.$repository.teachers
                            .delete(teacher.id)
                            ._loading(() => this.loadings.deleting = !this.loadings.deleting)
                            .then(() => {
                                this.$router.back()
                            })
                    }
                }
            })
        },
        assign() {
            this.$repository.teachers
                .assign(this.teacher.id, { target_teachers_ids: this.assigning })
                ._loading(() => this.loadings.assigning = !this.loadings.assigning)
                .then(() => {
                    this.assignTeachers = !this.assignTeachers
                    this.fetch()
                })
        }
    },
    mounted() {
        this.fetch()

        this.$repository.teachers
            .index({ limit: 0, enabled: true, is_coordinator: false })
            ._loading(() => this.loadings.teachers = !this.loadings.teachers)
            .then(({ data }) => {
                this.teachers = data.map($0 => ({ label: `${$0.first_name} ${$0.last_name}`, value: $0.id }))
            })

        this.$repository.periods.index({ limit: 0 })
            .then(({ data: periods }) => {
                this.periods = periods
                this.period = periods.find($0 => $0.enabled)?.id
            })
    }
}
</script>
